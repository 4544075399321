import React, { useState } from "react";

/** Vendors */
import Link from "react-anchor-link-smooth-scroll";
import { NavBar } from "antd-mobile";
import { withAuth0 } from "@auth0/auth0-react";
import { Button, Flex, Layout, Menu } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

/** Custom Components */
import NavSidebar from "./Sidebar";

/** Custom CSS */
import "./navbar.css";

/** Custom Images */
import LogoImage from "@dist/img/brand/bu-logo-text-transparent.png";
import LogoImageSmall from "@dist/img/brand/bu-logo-white-512-transparent.png";

function BUNavbar({ auth0 }) {
  const [show, setShow] = useState(false);

  const actions = {
    onSelect: (item) => {
      if (item.key === "navbar-option-5") {
        auth0.loginWithRedirect();
      }
    },
  };

  const items = [
    {
      label: <Link href="#home">Home</Link>,
      key: "navbar-option-1",
    },
    {
      label: <Link href="#about">About</Link>,
      key: "navbar-option-2",
    },
    {
      label: <Link href="#services">Services</Link>,
      key: "navbar-option-3",
    },
    {
      label: <Link href="#contact">Contact</Link>,
      key: "navbar-option-4",
    },
    {
      label: <Link href="/login">Login</Link>,
      key: "navbar-option-5",
    },
  ];

  if (window.innerWidth < 585) {
    return (
      <React.Fragment>
        <NavBar
          className="background-gradient"
          back={null}
          right={
            <Button onClick={() => setShow(!show)} type="text">
              {show ? (
                <MenuUnfoldOutlined
                  style={{ color: "#dedede", fontSize: "1.5rem" }}
                />
              ) : (
                <MenuFoldOutlined
                  style={{ color: "#dedede", fontSize: "1.5rem" }}
                />
              )}
            </Button>
          }
        >
          <div
            alt="BU Software"
            className="logo"
            style={{ backgroundImage: `url(${LogoImageSmall})` }}
          />
        </NavBar>
        <NavSidebar show={show} />
      </React.Fragment>
    );
  }

  return (
    <Layout.Header
      breakpoint="xxs"
      className="background-gradient"
      style={{
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div
        alt="Blue Umbrella"
        className="logo"
        style={{ backgroundImage: `url(${LogoImage})` }}
      />
      <Menu
        className="background-gradient"
        defaultSelectedKeys={["navbar-option-1"]}
        items={items}
        mode="horizontal"
        onSelect={actions.onSelect}
        style={{ justifyContent: "flex-end", flex: 1, minWidth: 0 }}
      />
    </Layout.Header>
  );
}
export default withAuth0(BUNavbar);
