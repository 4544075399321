import type { IStringObject } from "types";

export default async function contactFormApi({
  cellphone,
  email,
  message,
  name,
}: IStringObject): Promise<string> {
  if (!email || !name || !message || !cellphone) return "fields";
  if (message && message.length < 10) return "description";

  const res = await fetch(
    `${import.meta.env.VITE_REACT_APP_API_ENDPOINT}/member/contact`,
    {
      headers: {
        "Content-Type": "application/json",
        "x-bu-client": import.meta.env.VITE_REACT_APP_BU_CLIENT_RESOURCE_NAME,
        "x-bu-app": import.meta.env.VITE_REACT_APP_BU_APP_RESOURCE_NAME,
      },
      method: "PUT",
      body: JSON.stringify({
        member: {
          cellphone,
          email,
          message,
          name,
        },
        type: "MEMBER_CONTACT_SUCCESS",
      }),
    }
  ).then((res) => res.json());

  if (res.message === "success") return "success";
  else if (res.message === "error") throw new Error("Notification Error");
}
