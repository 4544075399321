/** Vendors */
import { SideBar } from "antd-mobile";
import { useNavigate } from "react-router-dom";

export default function NavSidebar({ show }: { show: boolean }) {
  const navigate = useNavigate();

  const actions = {
    onClick: (key: string) => {
      const link = items.find((item) => item.key === key);
      navigate(String(link?.target));
    },
  };

  const items = [
    {
      label: "Home",
      key: "navbar-option-1",
      target: "#home",
    },
    {
      label: "About",
      key: "navbar-option-2",
      target: "#about",
    },
    {
      label: "Services",
      key: "navbar-option-3",
      target: "#services",
    },
    {
      label: "Contact",
      key: "navbar-option-4",
      target: "#contact",
    },
    {
      label: "Legal",
      key: "navbar-option-5",
      target: "/legal",
    },
  ];

  return (
    <SideBar
      className={show ? "sidebar" : "sidebar hidden"}
      onChange={actions.onClick}
    >
      {items.map((item) => (
        <SideBar.Item key={item.key} title={item.label} />
      ))}
    </SideBar>
  );
}
