import React, { useState } from "react";

/** Vendors */
import { Button, Form, Input, Typography, notification } from "antd";

/** Custom Components */
import SubmitBoundary from "./SubmitBoundary";

/** Custom APIs */
import contactFormApi from "@api/contact";

interface FormFields {
  cellphone: string;
  email: string;
  message: string;
  name: string;
}

function ContactForm(): React.ReactNode {
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<string>("none");
  const [form] = Form.useForm();

  const actions = {
    onFinish: () => {
      setLoading(true);
      setResponse("");
      const values = form.getFieldsValue(true) as FormFields;

      contactFormApi(values)
        .then(actions.responseSuccess)
        .then(actions.responseNotification)
        .catch(actions.responseFailure);
    },
    responseNotification: () => {
      api.success({
        description:
          "We appreciate your interest and will be in touch shortly.",
        message: "Request Sent!",
      });
    },
    responseSuccess: (outcome: any) => setResponse(outcome),
    responseFailure: (e: any) => {
      console.log("Failed request: ", e);
    },
  };

  return (
    <SubmitBoundary loading={loading} response={response}>
      {contextHolder}
      {response === "fields" ? (
        <Typography.Paragraph className="text-center text-color-white">
          Seems your missing (1) or more fields
        </Typography.Paragraph>
      ) : null}
      {response === "description" ? (
        <Typography.Paragraph className="text-center text-color-white">
          Please include a more details request
        </Typography.Paragraph>
      ) : null}
      <Form form={form} name="contact" onFinish={actions.onFinish} size="large">
        <Form.Item name="name" rules={[{ required: true }]}>
          <Input placeholder="Enter Your Name" />
        </Form.Item>
        <Form.Item name="email" rules={[{ required: true }]}>
          <Input placeholder="Enter Your Email" type="email" />
        </Form.Item>
        <Form.Item name="cellphone" rules={[{ required: true }]}>
          <Input placeholder="Enter Your Telephone Number" type="tel" />
        </Form.Item>
        <Form.Item name="message" rules={[{ required: true }]}>
          <Input.TextArea
            placeholder="Description of Service / Project"
            minLength={20}
            rows={5}
          />
        </Form.Item>
        <Button block htmlType="submit" size="large" type="primary">
          Submit
        </Button>
      </Form>
    </SubmitBoundary>
  );
}

export default ContactForm;
