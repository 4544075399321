import React from "react";
import ReactDOM from "react-dom/client";

/** Vendors */
import { Auth0Provider } from "@auth0/auth0-react";

/** Custom Components */
import App from "./App";

/** Custom CSS */
import "./dist/css/app.css";
import "./dist/css/button.css";
import "./dist/css/color.css";
import "./dist/css/index.css";
import "./dist/css/format.css";
import "./dist/css/text.css";

const rootElement = document.getElementById("root");

ReactDOM.createRoot(rootElement!).render(
  <React.StrictMode>
    <Auth0Provider
      authorizationParams={{
        redirect_uri: import.meta.env.VITE_REACT_APP_REDIRECT_URL,
      }}
      clientId={import.meta.env.VITE_REACT_APP_AUTH_CLIENT_ID}
      domain={import.meta.env.VITE_REACT_APP_AUTH_DOMAIN}
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>
);
