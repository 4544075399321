import React from "react";

//Vendor
import { Button, Result } from "antd";

function Projects() {
  return (
    <Result
      extra={
        <Button type="primary" key="console">
          Return Home
        </Button>
      }
      title="Our projects list will be coming soon..."
    />
  );
}

export default Projects;
