/** Vendors */
import { Link } from "react-router-dom";
import { Col, Flex, Row, Typography } from "antd";

function ContactForm() {
  const isMobile = window.innerWidth < 585;

  return (
    <Row align="middle" gutter={[12, 12]} justify="center">
      <Col className={isMobile ? "text-center" : ""} sm={10} xs={22}>
        <Typography.Text className="text-color-white">
          &copy; {new Date().getFullYear()}&nbsp; Blue Umbrella LLC. All rights
          reserved
        </Typography.Text>
      </Col>
      <Col sm={10} xs={22}>
        <Flex flex={1} gap={12} justify={isMobile ? "center" : "flex-end"}>
          <Link className="text-color-white pointer" to="/legal#cookies">
            Cookie Policy
          </Link>
          <Link className="text-color-white pointer" to="/legal#terms">
            Terms of Service
          </Link>
          <Link className="text-color-white pointer" to="/legal#privacy">
            Privacy Policy
          </Link>
        </Flex>
      </Col>
    </Row>
  );
}
export default ContactForm;
