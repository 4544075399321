/** Vendors */
import { Card, Col, Typography } from "antd";

/** Types */
import type { ReactElement } from "react";

interface Props {
  children: JSX.Element | JSX.Element[];
  loading: boolean;
  response: string;
}

function SubmitBoundary({ children, loading, response }: Props): ReactElement {
  return (
    <Col sm={{ order: 2, span: 10 }} xs={{ order: 1, span: 24 }}>
      <Card>
        {loading ? (
          <div>
            <Typography.Title className="text-color-2" level={4}>
              {response === "success"
                ? "Thanks for reaching out! Your one step closer to solving that dream app or process improvement idea"
                : "Hmm... seems we encountered a problem! We are on the case though, please contact us through the email for now."}
            </Typography.Title>
            <br />
            {response === "success" ? (
              <Typography.Title className="text-color-2" level={4}>
                We will be in touch shortly
              </Typography.Title>
            ) : (
              <Typography.Link
                className="text-color-2"
                href="mailto:support@blueumbrella.consulting"
                style={{ fontSize: "1rem" }}
                target="_blank"
              >
                Email Us Instead @ support@blueumbrella.consulting
              </Typography.Link>
            )}
          </div>
        ) : (
          children
        )}
      </Card>
    </Col>
  );
}

export default SubmitBoundary;
