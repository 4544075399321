import { Suspense } from "react";

/** Vendors */
import { Layout } from "antd";
import { Outlet } from "react-router-dom";

/** Custom Components */
import Footer from "../footer/Footer";
import Navbar from "../navbar/Navbar";

function CommonLayout() {
  return (
    <div className="w-100">
      <Navbar />
      <Layout.Content>
        <Suspense fallback={<>Loading...</>}>
          <Outlet />
        </Suspense>
      </Layout.Content>
      <Footer />
    </div>
  );
}

export default CommonLayout;
