/** Vendors */
import { Layout } from "antd";

/** Custom Components */
import LegalTabs from "../cards/Tabs";

function LegalView() {
  return (
    <Layout>
      <Layout.Content>
        <LegalTabs />
      </Layout.Content>
    </Layout>
  );
}

LegalView.displayName = "Legal";

export default LegalView;
