import React from "react";

/** Vendors */
import { Col, Flex, Row, Typography } from "antd";

/** Custom Images */
import CellPhoneImage from "@dist/img/contact/cellphone.svg";
import EmailImage from "@dist/img/contact/email.svg";
import FacebookImage from "@dist/img/social/facebook.svg";
import GithubImage from "@dist/img/social/github.svg";
import LinkedInImage from "@dist/img/social/linkedin.svg";
import MarketAltImage from "@dist/img/contact/marker-alt.svg";

/** Custom CSS */
import "./contact.css";

function ContactInfo() {
  return (
    <Col sm={{ order: 1, span: 10 }} xs={{ order: 2, span: 24 }}>
      <Flex gap={12} vertical>
        <Typography.Title className="text-color-white" level={4}>
          Get In Touch With Us
        </Typography.Title>

        <Typography.Text className="text-color-white">
          We would love to hear about your next project.
        </Typography.Text>
        <Row align="middle" gutter={[12, 12]}>
          <Col md={2} sm={3} xs={4}>
            <img
              alt="Contact Number"
              className="icon-image m-auto"
              src={CellPhoneImage}
              style={{ maxHeight: 40 }}
              width={40}
            />
          </Col>
          <Col md={22} sm={21} xs={20}>
            <Typography.Link
              className="text-color-white"
              href="tel:+15012326326"
            >
              (501) 232-6326
            </Typography.Link>
          </Col>
          <Col md={2} sm={3} xs={4}>
            <img
              alt="Email Blue Umbrella"
              className="icon-image"
              height="100%"
              src={EmailImage}
              style={{ maxHeight: 40 }}
              width="100%"
            />
          </Col>
          <Col md={22} sm={21} xs={20}>
            <Typography.Link
              className="text-color-white"
              href="mailto:support@blueumbrella.consulting"
              rel="noopener noreferrer"
              target="_blank"
            >
              support@blueumbrella.consulting
            </Typography.Link>
          </Col>
          <Col md={2} sm={3} xs={4}>
            <img
              alt="Address"
              className="icon-image"
              height="100%"
              src={MarketAltImage}
              style={{ maxHeight: 40 }}
              width="100%"
            />
          </Col>
          <Col md={22} sm={21} xs={20}>
            <Typography.Text className="text-color-white">
              San Diego, California
            </Typography.Text>
          </Col>
          <Col md={2} sm={4} xs={{ span: 4 }}>
            <Typography.Link
              href="https://www.facebook.com/blueumbrella.consulting"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                alt="Facebook"
                className="icon-image"
                height="100%"
                src={FacebookImage}
                style={{ maxHeight: 40 }}
                width="100%"
              />
            </Typography.Link>
          </Col>
          <Col md={2} sm={4} xs={4}>
            <Typography.Link
              href="https://github.com/busoftware"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                alt="GitHub"
                className="icon-image"
                height="100%"
                src={GithubImage}
                style={{ maxHeight: 40 }}
                width="100%"
              />
            </Typography.Link>
          </Col>
          <Col md={2} sm={4} xs={4}>
            <Typography.Link
              href="https://www.linkedin.com/company/blueumbrellasoftware"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                alt="LinkedIn"
                className="icon-image"
                height="100%"
                src={LinkedInImage}
                style={{ maxHeight: 40 }}
                width="100%"
              />
            </Typography.Link>
          </Col>
        </Row>
      </Flex>
    </Col>
  );
}
export default ContactInfo;
