import { useEffect, useRef, useState } from "react";

/** Vendors */
import { LeftOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Col, Row, Tabs } from "antd";

export default function LegalTabs() {
  const [index, setIndex] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const scriptRef = useRef<HTMLScriptElement>(null);

  useEffect(() => {
    if (location.hash === "#cookies") {
      setIndex(import.meta.env.VITE_REACT_APP_LEGAL_COOKIE_POLICY);
    } else if (location.hash === "#terms") {
      setIndex(import.meta.env.VITE_REACT_APP_LEGAL_TERMS_AND_CONDITIONS);
    } else {
      setIndex(import.meta.env.VITE_REACT_APP_LEGAL_PRIVACY_POLICY);
    }
  }, []);

  useEffect(() => {
    (function (d, s, id) {
      const tjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        scriptRef.current = d.getElementById(id);
        return;
      }
      scriptRef.current = d.createElement(s);
      scriptRef.current.id = id;
      scriptRef.current.src = "https://app.termly.io/embed-policy.min.js";
      tjs.parentNode.insertBefore(scriptRef.current, tjs);
      window.scrollTo(0, 0);
    })(document, "script", "termly-jssdk");

    return () => scriptRef?.current?.remove();
  }, [index]);

  const policies = [
    {
      key: import.meta.env.VITE_REACT_APP_LEGAL_COOKIE_POLICY as string,
      label: "Cookie Policy",
    },
    {
      key: import.meta.env.VITE_REACT_APP_LEGAL_PRIVACY_POLICY as string,
      label: "Privacy Policy",
    },
    {
      key: import.meta.env.VITE_REACT_APP_LEGAL_TERMS_AND_CONDITIONS as string,
      label: "Terms & Conditions",
    },
  ];

  return (
    <div className="legal">
      <Row align="middle" gutter={[12, 12]} justify="center">
        <Col md={16} sm={20} xs={22}>
          <Tabs
            activeKey={index}
            centered
            items={policies.map((policy) => {
              return {
                label: policy.label,
                key: policy.key,
                children: (
                  <div
                    className="legal-height"
                    data-id={policy.key}
                    data-type="iframe"
                    name="termly-embed"
                  />
                ),
              };
            })}
            onTabClick={(key) => setIndex(key)}
            tabBarExtraContent={{
              left: (
                <Button onClick={() => navigate(-1)} type="text">
                  <LeftOutlined /> Go Back
                </Button>
              ),
            }}
          />
        </Col>
      </Row>
    </div>
  );
}
