import { Fragment, Suspense, lazy } from "react";

/** Vendors */
import { Layout } from "antd";

/** Custom CSS */
import "../style.css";

/** Custom Components */
import Header from "../cards/Header";
const AboutSection = lazy(() => import("../cards/About"));
const Clients = lazy(() => import("../cards/Clients"));
const ServicesSection = lazy(() => import("../cards/Services"));

function Home() {
  const { Content } = Layout;

  return (
    <Layout>
      <Header />
      <Suspense fallback={<Fragment></Fragment>}>
        <Content>
          <Clients />
          <AboutSection />
          <ServicesSection />
        </Content>
      </Suspense>
    </Layout>
  );
}

export default Home;
