/** Vendors */
import { createBrowserRouter } from "react-router-dom";

/** Custom Components */
import CommonLayout from "../components/shared/layout/Common";
import Home from "../components/home/views/Home";
import Legal from "../components/legal/views/Legal";
import Projects from "../components/projects/views/List";
import SmartHome from "../components/smarthome/views/SmartHome";

const router = createBrowserRouter([
  {
    element: <CommonLayout />,
    path: "/",
    children: [
      {
        element: <Home />,
        index: true,
      },
      {
        element: <Legal />,
        path: "/legal",
      },
      {
        element: <Projects />,
        path: "/projects",
      },
      {
        element: <SmartHome />,
        path: "/projects/smarthome",
      },
    ],
  },
]);

export default router;
