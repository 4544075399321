import React, { Suspense } from "react";

/** Vendors */
import { ConfigProvider } from "antd";
import { RouterProvider } from "react-router-dom";

/** Custom Components */
import router from "./router/index";

const theme = {
  token: {
    borderRadius: 4,
    borderRadiusLG: 8,
    colorLink: "#fff",
    colorPrimary: "#002c54",
    fontFamily: "Poppins",
  },
};

function App(): React.ReactNode {
  return (
    <ConfigProvider theme={theme}>
      <Suspense fallback={<React.Fragment>Loading...</React.Fragment>}>
        <RouterProvider router={router} />
      </Suspense>
    </ConfigProvider>
  );
}

export default App;
