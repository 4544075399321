import React from "react";

/** Vendors */
import { Col, Flex, Layout, Row } from "antd";

/** Custom Components */
import ContactInfo from "./Info";
import ContactForm from "./Form";
import Legal from "./Legal";

function Footer() {
  return (
    <Layout.Footer id="contact" className="colored-background p-4">
      <Flex gap={12} vertical>
        <Row align="middle" gutter={[12, 12]} justify="center">
          <ContactInfo />
          <ContactForm />
        </Row>
        <Row align="middle" gutter={[12, 12]} justify="center">
          <Col sm={20} xs={22}>
            <hr />
          </Col>
        </Row>
        <Legal />
      </Flex>
    </Layout.Footer>
  );
}
export default Footer;
