import React from "react";

/** Vendors */
import Link from "react-anchor-link-smooth-scroll";
import { Button, Col, Flex, Row, Space, Typography } from "antd";

/** Custom Images */
import WalkingImage from "@dist/img/bu-walking.png";

function Header() {
  return (
    <Row align="middle" className="background-gradient" justify="center">
      <Col justify="center" sm={10} xs={22}>
        <Space direction="vertical" size="large">
          <Typography.Title className="text-color-white" level={2}>
            Blue Umbrella is a&nbsp;
            <span className="text-color-1 bold">veteran owned</span> software
            development company. We will bring your idea to life and scale with
            you.
          </Typography.Title>
          <Flex gap={12}>
            <Link href="#contact">
              <Button className="btn-1" size="large">
                GET STARTED
              </Button>
            </Link>
            <Link href="#about">
              <Button className="btn-1 bordered" size="large">
                LEARN MORE
              </Button>
            </Link>
          </Flex>
        </Space>
      </Col>
      <Col align="middle" sm={10} xs={24}>
        <img
          alt="Blue Umbrella - Software"
          src={WalkingImage}
          style={{ height: "80%", margin: "10%", width: "80%" }}
        />
      </Col>
    </Row>
  );
}
export default Header;
